import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import objectSupport from "dayjs/plugin/objectSupport.js";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

import LoginPage from "./page/signin/login";
import Page404 from "./page/notfound/index";
import HomePage from "./page/home";
import HomeContent from "./page/home/content";
import UserRouter from "./page/user/index";
import ProductRouter from "./page/product/index";
import CategoryRouter from "./page/category/index";
import NotifyRouter from "./page/notify/index";
import ColorRouter from "./page/color/index";
import FileRouter from "./page/file/index";
import ColorElementRouter from "./page/colorElement/index";



dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(objectSupport);

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />}>
            <Route path="/" element={<HomeContent />} />
            <Route path="/home" element={<HomeContent />} />
            <Route path="/user/*" element={<UserRouter />} />
            <Route path="/product/*" element={<ProductRouter />} />
            <Route path="/category/*" element={<CategoryRouter />} />
            <Route path="/color/*" element={<ColorRouter />} />
            <Route path="/element/*" element={<ColorElementRouter />} />
            <Route path="/notify/*" element={<NotifyRouter />} />
            <Route path="/file/*" element={<FileRouter />} />
            <Route path="/*" element={<Page404 />} />
          </Route>
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
