import { message } from "antd";

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Chỉ được phép upload ảnh JPG/PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Ảnh phải có dung lượng nhỏ hơn 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
