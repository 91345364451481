import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useMount } from "ahooks";
import { Image, Skeleton, Upload, message } from "antd";
import { Formik } from "formik";
import {
  Form,
  FormItem,
  Input,
  InputNumber,
  Select,
  SubmitButton,
  Switch,
} from "formik-antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import FormikRichText from "../../component/richtext";
import { beforeUpload } from "../../helper/upload_helper";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";

export default function CrudPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [model, setModel] = useState({
    name: "",
    thumbnail: "",
    price: 0,
    contactPrice: 0,
    description: "",
    status: 0,
  });

  const fetchDetail = async function (id) {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.PRODUCT_DETAIL, {
        params: {
          productId: id,
        },
      });
      if (rs && rs.status === 0) {
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? rs?.data ?? "Lấy thông tin thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const update = async function (values, actions) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_UPDATE, {
        productId: id,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thành công");
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const create = async function (values, actions) {
    if (values.status === undefined || values.status === null) {
      values.status = 0;
    }

    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_CREATE, {
        productId: null,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Tạo mới thành công");
        navigate("/product");
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useMount(() => {
    if (id) {
      fetchDetail(id);
    }
  }, [id]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="bg-body px-3 rounded">
          <Formik
            initialValues={model}
            onSubmit={(values, actions) => {
              if (id) {
                update(values, actions);
              } else {
                create(values, actions);
              }
            }}
            validationSchema={Yup.object({})}
            validateOnChange={false}
            validateOnBlur={true}
            validateOnMount={true}
            enableReinitialize={true}
            children={({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
                size="middle"
              >
                <FormItem name="thumbnail" required={false} label="Ảnh">
                  <Upload
                    name="thumbnail"
                    listType="picture-card"
                    showUploadList={false}
                    maxCount={1}
                    multiple={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => {
                      if (info.file.status === "uploading") {
                        setLoadingImage(true);
                      } else if (info.file.status === "done") {
                        setLoadingImage(false);
                      } else {
                        setLoadingImage(false);
                      }
                    }}
                    customRequest={async ({ file, onSuccess, onError }) => {
                      const formData = new FormData();
                      formData.append("file", file);
                      try {
                        const response = await axiosClient.post(
                          APIConst.UPLOAD_FILE,
                          formData,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );
                        if (response) {
                          setFieldValue("thumbnail", response);
                          onSuccess(response, file);
                        } else {
                          onError(response?.message ?? "Upload thất bại");
                        }
                      } catch (err) {
                        onError(err.message);
                      }
                    }}
                  >
                    {values.thumbnail ? (
                      <Image
                        src={values.thumbnail}
                        alt="avatar"
                        preview={false}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <button
                        style={{
                          border: 0,
                          background: "none",
                        }}
                        type="button"
                      >
                        {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Upload
                        </div>
                      </button>
                    )}
                  </Upload>
                </FormItem>
                <FormItem name="name" required={true} label="Tên sản phẩm">
                  <Input
                    name="name"
                    placeholder="Chưa có thông tin"
                    size="middle"
                    allowClear={true}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="false"
                  />
                </FormItem>
                <FormItem name="contactPrice" required={false} label="Loại giá">
                  <Select
                    name="contactPrice"
                    size="middle"
                    defaultValue={0}
                    style={{
                      width: 300,
                    }}
                    onChange={(value) => {
                      setFieldValue("contactPrice", value);
                    }}
                    options={[
                      {
                        label: "Giá liên hệ",
                        value: 1,
                      },
                      {
                        label: "Giá tùy chỉnh",
                        value: 0,
                      },
                    ]}
                  />
                </FormItem>
                <FormItem name="price" required={false} label="Giá">
                  <InputNumber
                    name="price"
                    size="middle"
                    style={{
                      width: 300,
                    }}
                    disabled={values.contactPrice === 1 ? true : false}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </FormItem>
                <FormItem name="status" required={false} label="Trạng thái">
                  <Switch
                    name="status"
                    size="middle"
                    defaultChecked={true}
                    checked={values.status === 1 ? false : true}
                    onChange={(checked) => {
                      setFieldValue("status", checked === true ? 0 : 1);
                    }}
                    checkedChildren="0"
                    unCheckedChildren="1"
                  />
                </FormItem>

                <FormItem
                  name="description"
                  required={false}
                  label="Mô tả sản phẩm"
                >
                  <FormikRichText
                    id="frt"
                    value={values.description}
                    setValue={(value) => {
                      console.log(value);
                      setFieldValue("description", value);
                    }}
                  />
                </FormItem>

                <div className="d-flex flex-column align-items-start">
                  <SubmitButton
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{
                      width: "150px",
                    }}
                  >
                    {id ? "Cập nhật" : "Tạo mới"}
                  </SubmitButton>
                </div>
              </Form>
            )}
          ></Formik>
        </div>
      )}
    </div>
  );
}
