import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
      <Result
        status="404"
        title="404"
        subTitle="Trang này không tồn tại, vui lòng quay về"
        extra={
          <Button
            type="primary"
            size="middle"
            onClick={(e) => {
              navigate("/", { replace: true });
            }}
          >
            Về Trang chủ
          </Button>
        }
      />
    </div>
  );
}
