import { Typography, message } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import * as Yup from "yup";
import { adminAtom } from "../../data/atoms";
import APIConst from "../../network/apiconst";
import axiosClient, { udpateAxiosToken } from "../../network/restclient";
import "./login.css";

export default function LoginPage() {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  const setAdminAtom = useSetRecoilState(adminAtom);

  const login = async function (values, actions) {
    try {
      const rs = await axiosClient.post(APIConst.LOGIN, {
        username: values.username,
        password: values.password,
      });
      if (rs && rs.status === 0) {
        udpateAxiosToken(rs.data.token);
        setAdminAtom(rs.data);
        navigate("/");
        message.success("Đăng nhập thành công");
      } else {
        message.error(rs?.message ?? "Đăng nhập thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    actions.setSubmitting(false);
  };

  return (
    <div className="d-flex flex-row w-100 h-100">
      <div className="blur">
        <Title level={1} style={{ color: "white" }}>
          MiniGarage
        </Title>
        <Title level={3} style={{ color: "white" }}>
          Hệ thống quản lý garage
        </Title>
      </div>
      <div className="d-flex flex-column w-50 vh-100 align-items-center justify-content-center p-0">
        <Title className="p-1" level={2}>
          Đăng nhập
        </Title>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={(values, actions) => {
            login(values, actions);
          }}
          validationSchema={Yup.object({
            username: Yup.string().required("Bắt buộc nhập"),
            password: Yup.string().required("Bắt buộc nhập"),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
          validateOnMount={true}
          children={({ values, errors, setFieldValue }) => (
            <Form
              labelCol={{ span: 20 }}
              wrapperCol={{ span: 35 }}
              labelWrap={true}
              layout="vertical"
            >
              <FormItem
                name="username"
                required={true}
                label={<Text type="secondary">Tên đăng nhập</Text>}
              >
                <Input
                  name="username"
                  size="middle"
                  placeholder="Tên đăng nhập"
                  allowClear={true}
                  autoCapitalize="false"
                />
              </FormItem>
              <FormItem
                name="password"
                required={true}
                label={<Text type="secondary">Mật khẩu</Text>}
              >
                <Input.Password
                  name="password"
                  size="middle"
                  placeholder="Mật khẩu"
                  allowClear={true}
                  autoCapitalize="false"
                />
              </FormItem>

              <div className="d-flex flex-column align-items-center">
                <SubmitButton
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginTop: 16,
                    width: 150,
                  }}
                >
                  Đăng nhập
                </SubmitButton>
              </div>
            </Form>
          )}
        ></Formik>
      </div>
    </div>
  );
}
