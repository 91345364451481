import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  ColorPicker,
  Image,
  Modal,
  Popconfirm,
  Skeleton,
  Table,
  Typography,
  Upload,
  message,
} from "antd";
import { Formik } from "formik";
import {
  Form,
  FormItem,
  Input,
  Select,
  SubmitButton,
  Switch,
} from "formik-antd";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { beforeUpload } from "../../helper/upload_helper";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";
import ElemntCrudPage from "./element/crud";

export default function CrudPage() {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingElement, setLoadingElement] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [model, setModel] = useState({
    colorCode: "#FFFFFF",
    code: "",
    name: "",
    description: "",
    carBrandId: -1,
    carVehiclesId: -1,
    carModelId: -1,
    paintingType: null,
    colorType: null,
    status: 0,
    colorElements: [],
  });
  const [carBrands, setCarBrands] = useState([]);
  const [carVehicles, setCarVehicles] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [paints, setPaints] = useState([]);
  const [loadingCarBrand, setLoadingCarBrand] = useState(false);
  const [loadingCarVehicles, setLoadingCarVehicles] = useState(false);
  const [loadingCarModel, setLoadingCarModel] = useState(false);
  const [loadingPaint, setLoadingPaint] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  const fetchDetail = async function (id) {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.COLOR_DETAIL, {
        params: {
          colorFormulaId: id,
        },
      });
      if (rs && rs.status === 0) {
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? rs?.data ?? "Lấy thông tin thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const update = async function (values, actions) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.COLOR_UPDATE, {
        colorFormulaId: id,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thành công");
        fetchDetail(id);
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const create = async function (values, actions) {
    if (values.status === undefined || values.status === null) {
      values.status = 0;
    }
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.COLOR_CREATE, {
        colorFormulaId: null,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Tạo mới thành công");
        navigate("/color");
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const fetchCarBrand = async () => {
    setLoadingCarBrand(true);
    try {
      const res = await axiosClient.get(APIConst.CAR_BRAND, {
        params: {},
      });
      if (res && res.status === 0) {
        res.data.sort((a, b) => a.name.localeCompare(b.name));
        res.data.unshift({
          carBrandId: -1,
          name: "Tất cả hãng",
        });
        setCarBrands((old) => res.data || []);
      } else {
        message.error(res.message ?? "Không lấy được danh sách brand");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoadingCarBrand(false);
  };

  const fetchCarVehicles = async (brandId) => {
    setLoadingCarVehicles(true);
    try {
      const res = await axiosClient.get(APIConst.CAR_VEHICLES, {
        params: {
          brandId: brandId,
        },
      });
      if (res && res.status === 0) {
        res.data.sort((a, b) => a.name.localeCompare(b.name));
        res.data.unshift({
          carVehiclesId: -1,
          name: "Tất cả dòng",
        });
        setCarVehicles((old) => res.data || []);
      } else {
        message.error(res.message ?? "Không lấy được danh sách vehicles");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoadingCarVehicles(false);
  };

  const fetchCarModel = async (vehiclesId) => {
    setLoadingCarModel(true);
    try {
      const res = await axiosClient.get(APIConst.CAR_MODEL, {
        params: {
          vehiclesId: vehiclesId,
        },
      });
      if (res && res.status === 0) {
        res.data.sort((a, b) => a.name.localeCompare(b.name));
        res.data.unshift({
          carModelId: -1,
          name: "Tất cả đời",
        });
        setCarModels((old) => res.data || []);
      } else {
        message.error(res.message ?? "Không lấy được danh sách model");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoadingCarModel(false);
  };

  const deleteElement = async function (id) {
    setLoadingElement(true);
    try {
      const rs = await axiosClient.get(APIConst.COLOR_ELEMENT_DELETE, {
        params: {
          colorElementId: id,
        },
      });
      if (rs && rs.status === 0) {
        model.colorElements = model.colorElements.filter(
          (el) => el.colorElementId !== id
        );
        setModel({ ...model });
        message.success("Xóa thành công");
      } else {
        message.error(rs?.message ?? "Xóa thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoadingElement(false);
  };

  useEffect(() => {
    const fetchPaint = async () => {
      setLoadingPaint(true);
      try {
        const res = await axiosClient.get(APIConst.COLOR_PAINT);
        if (res && res.status === 0) {
          res.data.sort((a, b) => a.name.localeCompare(b.name));
          setPaints((old) => res.data || []);
        } else {
          message.error(res.message ?? "Không lấy được danh sách model");
        }
      } catch (error) {
        message.error(error.message);
        console.log(error);
      }
      setLoadingPaint(false);
    };
    fetchPaint();
    if (id) {
      fetchDetail(id);
    }

    return () => {};
  }, [id]);

  useEffect(() => {
    if (model && model.code) {
      fetchCarBrand();
      fetchCarVehicles(model.carBrandId);
      fetchCarModel(model.carVehiclesId);
    } else {
      fetchCarBrand();
    }
    return () => {};
  }, [model]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      <Modal
        title={selectedElement ? selectedElement.code : "Thành phần mới"}
        open={isModalOpen}
        closable={true}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        destroyOnClose={true}
        footer={null}
        width={"50%"}
      >
        <ElemntCrudPage
          onAdd={(element) => {
            setIsModalOpen(false);
          }}
          colorFormulaId={id}
          element={selectedElement || null}
          onSuccess={(element) => {
            if (selectedElement) {
              model.colorElements = model.colorElements.map((el) =>
                el.colorElementId === element.colorElementId ? element : el
              );
            } else {
              if (!model.colorElements) {
                model.colorElements = [];
              }
              model.colorElements = [...model.colorElements, element];
            }
            setModel({ ...model });
            setIsModalOpen(false);
          }}
        />
      </Modal>

      {loading ? (
        <Skeleton />
      ) : (
        <div className="bg-body px-3 rounded">
          <div className="d-flex flex-row">
            <div className="w-50 h-100">
              <Formik
                initialValues={model}
                onSubmit={(values, actions) => {
                  if (id) {
                    update(values, actions);
                  } else {
                    create(values, actions);
                  }
                }}
                validationSchema={Yup.object({
                  code: Yup.string().required("Mã CTM không được để trống"),
                })}
                validateOnChange={false}
                validateOnBlur={true}
                validateOnMount={true}
                enableReinitialize={true}
                children={({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 13 }}
                    layout="horizontal"
                    size="middle"
                  >
                    <FormItem name="thumbnail" required={false} label="Ảnh">
                      <Upload
                        name="thumbnail"
                        listType="picture-card"
                        showUploadList={false}
                        maxCount={1}
                        multiple={false}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                          if (info.file.status === "uploading") {
                            setLoadingImage(true);
                          } else if (info.file.status === "done") {
                            setLoadingImage(false);
                          } else {
                            setLoadingImage(false);
                          }
                        }}
                        customRequest={async ({ file, onSuccess, onError }) => {
                          const formData = new FormData();
                          formData.append("file", file);
                          try {
                            const response = await axiosClient.post(
                              APIConst.UPLOAD_FILE,
                              formData,
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            );
                            if (response) {
                              setFieldValue("thumbnail", response);
                              onSuccess(response, file);
                            } else {
                              onError(response?.message ?? "Upload thất bại");
                            }
                          } catch (err) {
                            onError(err.message);
                          }
                        }}
                      >
                        {values.thumbnail ? (
                          <Image
                            src={values.thumbnail}
                            alt="avatar"
                            preview={false}
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loadingImage ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </button>
                        )}
                      </Upload>
                    </FormItem>
                    <FormItem
                      name="colorCode"
                      required={false}
                      label="Màu đại diện"
                    >
                      <ColorPicker
                        value={values.colorCode}
                        format="hex"
                        size="large"
                        onChangeComplete={(value) => {
                          setFieldValue("colorCode", value.toHexString());
                        }}
                      />
                    </FormItem>
                    <FormItem name="code" required={true} label="Mã CTM">
                      <Input
                        name="code"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                      />
                    </FormItem>
                    <FormItem name="name" required={true} label="Tên CTM">
                      <Input
                        name="name"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        fast={true}
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                      />
                    </FormItem>
                    <FormItem
                      name="description"
                      required={false}
                      label="Ghi chú"
                    >
                      <Input.TextArea
                        name="description"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                        rows={3}
                      />
                    </FormItem>
                    <FormItem
                      name="carBrandId"
                      required={false}
                      label="Hãng xe"
                    >
                      <Select
                        name="carBrandId"
                        size="middle"
                        showSearch={true}
                        defaultValue={-1}
                        placeholder="Chọn hãng xe"
                        loading={loadingCarBrand}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        options={carBrands.map((el) => ({
                          label: el.name,
                          value: el.carBrandId,
                        }))}
                        onChange={(value) => {
                          if (value === -1) {
                            setFieldValue("carVehiclesId", -1);
                            setFieldValue("carModelId", -1);
                          } else {
                            fetchCarVehicles(value);
                            setFieldValue("carVehiclesId", null);
                          }
                        }}
                      />
                    </FormItem>
                    <FormItem
                      name="carVehiclesId"
                      required={false}
                      label="Dòng xe"
                    >
                      <Select
                        name="carVehiclesId"
                        size="middle"
                        showSearch={false}
                        placeholder="Chọn dòng xe"
                        defaultValue={-1}
                        loading={loadingCarVehicles}
                        disabled={
                          !values.carBrandId || values.carBrandId === -1
                        }
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        options={carVehicles.map((el) => ({
                          label: el.name,
                          value: el.carVehiclesId,
                        }))}
                        onChange={(value) => {
                          if (value === -1) {
                            setFieldValue("carModelId", -1);
                          } else {
                            fetchCarModel(value);
                            setFieldValue("carModelId", null);
                          }
                        }}
                      />
                    </FormItem>
                    <FormItem name="carModelId" required={false} label="Đời xe">
                      <Select
                        name="carModelId"
                        size="middle"
                        showSearch={true}
                        defaultValue={-1}
                        placeholder="Chọn đời xe"
                        loading={loadingCarModel}
                        disabled={
                          !values.carVehiclesId || values.carVehiclesId === -1
                        }
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        options={carModels.map((el) => ({
                          label: el.name,
                          value: el.carModelId,
                        }))}
                      />
                    </FormItem>
                    <FormItem
                      name="paintingType"
                      required={false}
                      label="Loại sơn"
                    >
                      <Select
                        name="paintingType"
                        size="middle"
                        showSearch={true}
                        fast={true}
                        placeholder="Chọn loại sơn"
                        loading={loadingPaint}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input) ||
                          (option?.value ?? "").toLowerCase().includes(input)
                        }
                        options={paints
                          .filter((el) => el.type === "paint")
                          .map((el) => ({
                            label: el.name,
                            value: el.code,
                          }))}
                      />
                    </FormItem>

                    <FormItem
                      name="colorType"
                      required={false}
                      label="Nhóm màu"
                    >
                      <Input
                        name="colorType"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                        fast={true}
                      />
                    </FormItem>

                    <FormItem
                      name="colorOrigin"
                      required={false}
                      label="Xuất xứ"
                    >
                      <Input
                        name="colorOrigin"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        fast={true}
                        autoCapitalize="false"
                      />
                    </FormItem>

                    <FormItem name="creator" required={false} label="Sáng tác">
                      <Input
                        name="creator"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        fast={true}
                        autoCapitalize="false"
                      />
                    </FormItem>

                    <FormItem name="status" required={false} label="Trạng thái">
                      <Switch
                        name="status"
                        size="middle"
                        defaultChecked={true}
                        checked={values.status === 1 ? false : true}
                        onChange={(checked) => {
                          setFieldValue("status", checked === true ? 0 : 1);
                        }}
                        checkedChildren="0"
                        unCheckedChildren="1"
                      />
                    </FormItem>
                    <div className="d-flex flex-column align-items-start">
                      <SubmitButton
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        style={{
                          width: "150px",
                        }}
                      >
                        {id ? "Cập nhật" : "Tạo mới"}
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              />
            </div>
            <div className="w-50 h-100">
              <Table
                style={{ width: "100%", margin: 0, padding: 0 }}
                title={() => (
                  <div className="d-flex flex-row justify-content-between">
                    <Text>Thành phần màu</Text>
                    <Button
                      className="ms-2"
                      type="primary"
                      size="small"
                      icon={<PlusOutlined />}
                      disabled={!model || !model.colorFormulaId}
                      onClick={() => {
                        setSelectedElement(null);
                        setIsModalOpen(true);
                      }}
                    >
                      Thêm
                    </Button>
                  </div>
                )}
                dataSource={model.colorElements}
                loading={loadingElement}
                rowKey="colorElementId"
                pagination={{
                  pageSize: 10,
                  showTotal: (total) => `Tổng ${total} thành phần`,
                }}
                size="small"
                columns={[
                  {
                    title: "Mã",
                    dataIndex: "code",
                    key: "code",
                    align: "center",
                  },
                  {
                    title: "KL trộn",
                    dataIndex: "mixingWeight",
                    key: "mixingWeight",
                    align: "center",
                    render: (text) => (
                      <NumericFormat
                        value={text ?? 0}
                        decimalScale={2}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" gr"}
                      />
                    ),
                  },
                  {
                    title: "Giá bán/Kg",
                    dataIndex: "sellPricePerKg",
                    key: "sellPricePerKg",
                    align: "center",
                    render: (text) => (
                      <NumericFormat
                        value={text ?? 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={""}
                      />
                    ),
                  },
                  {
                    title: "+",
                    dataIndex: "action",
                    key: "action",
                    align: "center",
                    width: 100,
                    render: (text, item) => (
                      <div>
                        <Button
                          icon={<EditOutlined />}
                          size="small"
                          type="link"
                          onClick={() => {
                            setSelectedElement(item);
                            setIsModalOpen(true);
                          }}
                        ></Button>
                        <Popconfirm
                          title="Bạn có chắc chắn muốn xóa?"
                          onConfirm={() => {
                            deleteElement(item.colorElementId);
                          }}
                          okText="Có"
                          cancelText="Không, bỏ qua"
                        >
                          <Button
                            className="ms-3"
                            type="link"
                            danger
                            size="small"
                            icon={<DeleteOutlined />}
                          ></Button>
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
