import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useMount } from "ahooks";
import {
  Button,
  Image,
  List,
  Modal,
  Skeleton,
  Typography,
  Upload,
  message,
  InputNumber,
} from "antd";
import Link from "antd/es/typography/Link";
import { Formik } from "formik";
import {
  Form,
  FormItem,
  Input,
  SubmitButton,
  Switch,
  InputNumber as FormInputNumber,
} from "formik-antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { beforeUpload } from "../../helper/upload_helper";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";
import PickPage from "../product/pick";

export default function CrudPage() {
  const { Text, Paragraph } = Typography;

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [model, setModel] = useState({
    name: "",
    description: "",
    image: "",
    priority: 99,
    status: 0,
  });
  const [list, setList] = useState([]);
  const [showPickProduct, setShowPickProduct] = useState(false);

  const fetchProducts = async function (id) {
    setLoadingList(true);
    try {
      const rs = await axiosClient.get(APIConst.PRODUCT_CATE, {
        params: {
          categoryId: id,
        },
      });
      if (rs && rs.status === 0) {
        setList(rs.data);
      } else {
        message.error(
          rs?.message ?? rs?.data ?? "Lấy thông tin products thất bại"
        );
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoadingList(false);
  };

  const fetchDetail = async function (id) {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.CATE_DETAIL, {
        params: {
          categoryId: id,
        },
      });
      if (rs && rs.status === 0) {
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? rs?.data ?? "Lấy thông tin thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const update = async function (values, actions) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.CATE_UPDATE, {
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thành công");
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const create = async function (values, actions) {
    if (values.status === undefined || values.status === null) {
      values.status = 0;
    }
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.CATE_CREATE, {
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Tạo mới thành công");
        navigate("/category");
      } else {
        message.error(rs?.message ?? "Tạo mới thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const leaveCategory = async function (productId) {
    setLoadingList(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_LEAVE_CATE, {
        productId: productId,
        categoryId: id,
      });
      if (rs && rs.status === 0) {
        message.success("Xóa sản phẩm khỏi danh mục thành công");
        fetchProducts(id);
      } else {
        message.error(rs?.message ?? "Xóa sản phẩm khỏi danh mục thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoadingList(false);
  };

  const joinCategory = async function (productId) {
    setLoadingList(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_JOIN_CATE, {
        productId: productId,
        categoryId: id,
      });
      if (rs && rs.status === 0) {
        message.success("Thêm sản phẩm vào danh mục thành công");
        fetchProducts(id);
        setShowPickProduct(false);
      } else {
        message.error(rs?.message ?? "Thêm sản phẩm vào danh mục thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoadingList(false);
  };

  const changeSort = async function (productId, sort) {
    if (sort < 0) {
      message.error("Thứ tự phải lớn hơn 0");
      return;
    }
    setLoadingList(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_SORT_CATE, {
        productId: productId,
        categoryId: id,
        sort: sort,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thứ tự sản phẩm thành công");
        fetchProducts(id);
      } else {
        message.error(rs?.message ?? "Cập nhật thứ tự sản phẩm thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoadingList(false);
  };

  useMount(() => {
    if (id) {
      fetchDetail(id);
      fetchProducts(id);
    }
  }, [id]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="bg-body px-3 rounded">
          <Modal
            className="w-80"
            title="Chọn sản phẩm"
            open={showPickProduct}
            onCancel={() => {
              setShowPickProduct(false);
            }}
            footer={null}
            style={{
              minWidth: "80%",
            }}
          >
            <PickPage
              selectedIds={list.map((item) => item.productId)}
              onPick={(product) => {
                joinCategory(product.productId);
              }}
            />
          </Modal>
          <div className="d-flex flex-row">
            <div className="w-50 h-100">
              <Formik
                initialValues={model}
                onSubmit={(values, actions) => {
                  if (id) {
                    update(values, actions);
                  } else {
                    create(values, actions);
                  }
                }}
                validationSchema={Yup.object({})}
                validateOnChange={false}
                validateOnBlur={true}
                validateOnMount={true}
                enableReinitialize={true}
                children={({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    size="middle"
                  >
                    <FormItem name="image" required={false} label="Ảnh">
                      <Upload
                        name="image"
                        listType="picture-card"
                        showUploadList={false}
                        maxCount={1}
                        multiple={false}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                          if (info.file.status === "uploading") {
                            setLoadingImage(true);
                          } else if (info.file.status === "done") {
                            setLoadingImage(false);
                          } else {
                            setLoadingImage(false);
                          }
                        }}
                        customRequest={async ({ file, onSuccess, onError }) => {
                          const formData = new FormData();
                          formData.append("file", file);
                          try {
                            const response = await axiosClient.post(
                              APIConst.UPLOAD_FILE,
                              formData,
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            );
                            if (response) {
                              setFieldValue("image", response);
                              onSuccess(response, file);
                            } else {
                              onError(response?.message ?? "Upload thất bại");
                            }
                          } catch (err) {
                            onError(err.message);
                          }
                        }}
                      >
                        {values.image ? (
                          <Image
                            src={values.image}
                            alt="avatar"
                            preview={false}
                            style={{
                              width: 100,
                              height: 100,
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loadingImage ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </button>
                        )}
                      </Upload>
                    </FormItem>

                    <FormItem name="name" required={true} label="Tên Category">
                      <Input
                        name="name"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                      />
                    </FormItem>
                    <FormItem name="description" required={false} label="Mô tả">
                      <Input.TextArea
                        name="description"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        rows={4}
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                      />
                    </FormItem>
                    <FormItem
                      name="priority"
                      required={false}
                      label="Độ ưu tiên"
                    >
                      <FormInputNumber
                        name="priority"
                        placeholder="Chưa có thông tin"
                        size="middle"
                        defaultValue={0}
                        allowClear={true}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="false"
                      />
                    </FormItem>
                    <FormItem name="status" required={false} label="Trạng thái">
                      <Switch
                        name="status"
                        size="middle"
                        checked={values.status === 1 ? false : true}
                        onChange={(checked) => {
                          setFieldValue("status", checked === true ? 0 : 1);
                        }}
                        checkedChildren="0"
                        unCheckedChildren="1"
                      />
                    </FormItem>

                    <div className="d-flex flex-column align-items-start">
                      <SubmitButton
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        style={{
                          width: "150px",
                        }}
                      >
                        {id ? "Cập nhật" : "Tạo mới"}
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              ></Formik>
            </div>
            <div className="w-50 h-100">
              <List
                itemLayout="horizontal"
                loading={loadingList}
                dataSource={list}
                header={
                  <div className="d-flex justify-content-between">
                    <Text strong>{list.length} sản phẩm </Text>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setShowPickProduct(true);
                      }}
                      icon={<PlusOutlined />}
                    >
                      Thêm SP
                    </Button>
                  </div>
                }
                pagination={{
                  position: "bottom",
                  pageSize: 4,
                  total: list.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]}/${total} kết quả`,
                }}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      className="me-3"
                      style={{
                        width: "100%",
                      }}
                      avatar={
                        <Image width={50} height={50} src={item.thumbnail} />
                      }
                      title={
                        <Link
                          href={`/product/detail/${item.productId}`}
                          color="blue"
                        >
                          {item.productId} - {item.name}
                        </Link>
                      }
                      description={
                        <div>
                          <Paragraph ellipsis={{ rows: 2 }}>
                            {item.description
                              ? item.description.replace(/<[^>]+>/g, "")
                              : "Chưa có mô tả"}
                          </Paragraph>
                        </div>
                      }
                    />
                    <div className="d-flex flex-column align-items-center justify-content-between">
                      <Text type="secondary">Thứ tự</Text>
                      <InputNumber
                        key={item.productId}
                        value={item.sort}
                        min={1}
                        max={1000}
                        size="small"
                        width={80}
                        disabled={loadingList}
                        style={{
                          width: 70,
                        }}
                        onChange={(value) => {
                          item.sort = value;
                        }}
                        onPressEnter={() => {
                          changeSort(item.productId, item.sort);
                        }}
                      />
                      <Button
                        className="mt-2"
                        type="link"
                        htmlType="button"
                        size="small"
                        danger
                        loading={loadingList}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          leaveCategory(item.productId);
                        }}
                      >
                        Xóa
                      </Button>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
