import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  ColorPicker,
  Image,
  Input,
  Popconfirm,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import Link from "antd/es/typography/Link";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";

export default function ListPage() {
  const { Text, Paragraph } = Typography;

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({
    keyword: "",
    limit: 5,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(APIConst.COLOR_LIST, {
        params: {
          code: searchParams.get("keyword"),
          limit: searchParams.get("limit"),
          page: searchParams.get("page") - 1,
        },
      });
      if (res && res.status === 0) {
        setList(res.data.list ?? []);
        setTotalResult(res.data.total ?? 0);
      } else {
        message.error(res.message ?? "Không lấy được danh sách CTM");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteRecord = async (id) => {
    setLoading(true);
    try {
      const res = await axiosClient.get(APIConst.COLOR_DELETE, {
        params: {
          colorFormulaId: id,
        },
      });
      if (res && res.status === 0) {
        fetchData();
      } else {
        message.error(res.message ?? "Không xóa được CTM");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [
    searchParams.get("refresh"),
    searchParams.get("page"),
    searchParams.get("limit"),
  ]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      <div className="d-flex flex-row py-2 justify-content-between align-items-center">
        <div>
          <Input
            placeholder="Tìm kiếm mã màu"
            enterbutton="Tìm kiếm"
            name="search"
            value={searchParams.get("keyword")}
            allowClear={true}
            size="middle"
            onChange={(e) => {
              searchParams.set("keyword", e.target.value);
              setSearchParams(searchParams);
            }}
            style={{
              width: 300,
            }}
          />
          <Button
            type="primary"
            size="middle"
            onClick={(e) => {
              searchParams.set("page", 1);
              searchParams.set("refresh", Math.random());
              setSearchParams(searchParams);
            }}
            icon={<SearchOutlined />}
            className="ms-2"
          >
            Tìm kiếm
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/color/create");
            }}
          >
            Tạo mới
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <Table
          rowKey="_id"
          dataSource={list || []}
          loading={loading}
          bordered={true}
          size="small"
          scroll={{ x: "max-content" }}
          responsive={true}
          columns={[
            {
              title: "Thumbnail",
              dataIndex: "thumbnail",
              key: "thumbnail",
              align: "center",
              render: (text) => (
                <Image
                  width={50}
                  height={50}
                  src={text}
                  alt="thumbnail"
                  preview={false}
                  fallback="https://via.placeholder.com/50"
                />
              ),
            },
            {
              title: "Màu",
              dataIndex: "colorCode",
              key: "colorCode",
              align: "center",
              render: (text) => (
                <ColorPicker value={text} disabled size="large" />
              ),
            },
            {
              title: "Mã CTM",
              dataIndex: "code",
              key: "code",
              render: (text) => <Text>{text.toUpperCase()}</Text>,
            },
            {
              title: "Tên CTM",
              dataIndex: "name",
              key: "name",
              render: (text) => <Text>{text}</Text>,
            },
            {
              title: "Nhóm màu",
              dataIndex: "colorType",
              key: "colorType",
              align: "center",
              render: (text) => <Text>{text}</Text>,
            },
            {
              title: "Hãng xe",
              dataIndex: "carBrandThumbnail",
              key: "carBrandThumbnail",
              align: "center",
              render: (text, item) => (
                <Image
                  width={40}
                  src={text}
                  alt="thumbnail"
                  preview={false}
                  fallback="https://via.placeholder.com/50"
                />
              ),
            },
            {
              title: "Đời xe",
              dataIndex: "carModelName",
              key: "carModelName",
              render: (text) => <Text>{text}</Text>,
            },
            {
              title: "Ngày cập nhật",
              dataIndex: "updatedAt",
              key: "updatedAt",
              align: "center",
              width: 120,
              render: (text) => (
                <Text>
                  {dayjs(text, "DD/MM/YYYY HH:mm:ss").format("HH:mm DD/MM/YY")}
                </Text>
              ),
            },
            {
              title: "Trạng thái",
              dataIndex: "status",
              key: "status",
              width: 80,
              align: "center",
              render: (text) => (
                <Tag color={text === 0 ? "blue" : "magenta"} bordered={false}>
                  {text === 0 ? "Hoạt động" : "Khóa"}
                </Tag>
              ),
            },

            {
              title: "",
              dataIndex: "action",
              key: "action",
              fixed: "right",
              align: "center",
              width: 100,
              render: (text, item) => (
                <div>
                  <Link
                    href={`/color/detail/${encodeURIComponent(
                      item?.colorFormulaId
                    )}`}
                  >
                    <EditOutlined size="small" className="me-2" />
                    Sửa
                  </Link>
                  <Popconfirm
                    title="Bạn có chắc chắn muốn xóa?"
                    onConfirm={() => {
                      deleteRecord(item?.colorFormulaId);
                    }}
                    okText="Có"
                    cancelText="Không, bỏ qua"
                  >
                    <Button
                      className="ms-2"
                      type="link"
                      danger
                      icon={<DeleteOutlined size="small" />}
                    >
                      Xóa
                    </Button>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
          pagination={{
            onChange: (page, pageSize) => {
              searchParams.set("page", page);
              searchParams.set("limit", pageSize);
              setSearchParams(searchParams);
            },
            pageSize: searchParams.get("limit"),
            current: searchParams.get("page"),
            total: totalResult,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
            defaultPageSize: 5,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} / ${total} kết quả`,
          }}
        />
      </div>
    </div>
  );
}
