import {
  ApartmentOutlined,
  CloudUploadOutlined,
  FormatPainterOutlined,
  HomeOutlined,
  MessageOutlined,
  ProductOutlined,
  PushpinOutlined,
  UserOutlined,
} from "@ant-design/icons";
const menus = [
  {
    label: "Trang chủ",
    subtitle: "",
    key: "/",
    icon: <HomeOutlined />,
    keypath: "/",
  },
  {
    label: "Người dùng",
    subtitle: "Quản lý người dùng",
    key: "user",
    icon: <UserOutlined />,
    keypath: "/user",
  },
  {
    label: "Danh mục sản phẩm",
    subtitle: "Quản lý các danh mục sản phẩm",
    key: "category",
    icon: <ApartmentOutlined />,
    keypath: "/category",
    children: [
      {
        label: "Danh mục cơ bản",
        subtitle: "Quản lý danh mục cơ bản",
        key: "category/basic",
        keypath: "/category/",
        // icon: <ApartmentOutlined />,
      },
      {
        label: "Danh mục nổi bật",
        subtitle: "Quản lý danh mục nổi bật",
        key: "category/hot",
        keypath: "/category/hot",
        // icon: <PushpinOutlined />,
      },
    ],
  },
  {
    label: "Sản phẩm",
    subtitle: "Quản lý sản phẩm",
    key: "product",
    icon: <ProductOutlined />,
    keypath: "/product",
  },

  {
    label: "Công thức màu",
    subtitle: "Quản lý công thức màu",
    key: "color",
    icon: <FormatPainterOutlined />,
    keypath: "/color",
  },
  {
    label: "Thành phần màu",
    subtitle: "Quản lý thành phần màu",
    key: "element",
    icon: <FormatPainterOutlined />,
    keypath: "/element",
  },
  {
    label: "Thông báo đẩy",
    subtitle: "Thông báo đẩy cho người dùng ứng dụng di động",
    key: "notify",
    icon: <MessageOutlined />,
    keypath: "/notify",
  },
  {
    label: "Quản lý ảnh",
    subtitle: "Quản lý ảnh upload",
    key: "file",
    icon: <CloudUploadOutlined />,
    keypath: "/file",
  },
];
export default menus;
