import { useMount } from "ahooks";
import { Skeleton, message } from "antd";
import { Formik } from "formik";
import {
  Form,
  FormItem,
  Input,
  SubmitButton,
  Switch,
  InputNumber,
} from "formik-antd";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";

export default function CrudPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    code: "",
    name: "",
    sellPricePerKg: 0,
    status: 0,
  });

  const fetchDetail = async function (id) {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.COLOR_ELEMENT_CODE, {
        params: {
          code: id,
        },
      });
      if (rs && rs.status === 0) {
        setModel((old) => rs.data);
      } else {
        message.error(rs?.message ?? rs?.data ?? "Lấy thông tin thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const update = async function (values, actions) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.COLOR_ELEMENT_CODE, {
        action: 2,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thành công");
        fetchDetail(id);
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const create = async function (values, actions) {
    if (values.status === undefined || values.status === null) {
      values.status = 0;
    }
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.COLOR_ELEMENT_CODE, {
        action: 1,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Tạo mới thành công");
        navigate("/element");
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useMount(() => {
    if (id) {
      fetchDetail(id);
    }
    return () => {};
  }, [id]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="bg-body px-3 rounded">
          <Formik
            initialValues={model}
            onSubmit={(values, actions) => {
              if (id) {
                update(values, actions);
              } else {
                create(values, actions);
              }
            }}
            validationSchema={Yup.object({
              code: Yup.string().required("Mã TPM không được để trống"),
              name: Yup.string().required("Tên TPM không được để trống"),
            })}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            enableReinitialize={true}
            children={({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 7 }}
                layout="horizontal"
                size="middle"
              >
                <FormItem name="code" required={true} label="Mã TPM">
                  <Input
                    name="code"
                    placeholder="Chưa có thông tin"
                    size="middle"
                    allowClear={true}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="false"
                    disabled={id ? true : false}
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                  />
                </FormItem>
                <FormItem name="name" required={true} label="Tên TPM">
                  <Input
                    name="name"
                    placeholder="Chưa có thông tin"
                    size="middle"
                    allowClear={true}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="false"
                  />
                </FormItem>
                <FormItem
                  name="sellPricePerKg"
                  required={true}
                  label="Giá bán/Kg"
                >
                  <InputNumber
                    name="sellPricePerKg"
                    placeholder="Chưa có thông tin"
                    size="middle"
                    defaultValue={0}
                    allowClear={true}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="false"
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </FormItem>
                <FormItem name="status" required={false} label="Trạng thái">
                  <Switch
                    name="status"
                    size="middle"
                    defaultChecked={true}
                    checked={values?.status === 1 ? false : true}
                    onChange={(checked) => {
                      setFieldValue("status", checked === true ? 0 : 1);
                    }}
                    checkedChildren="0"
                    unCheckedChildren="1"
                  />
                </FormItem>
                <div className="d-flex flex-column align-items-start">
                  <SubmitButton
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{
                      width: "150px",
                    }}
                  >
                    {id ? "Cập nhật" : "Tạo mới"}
                  </SubmitButton>
                </div>
              </Form>
            )}
          />
        </div>
      )}
    </div>
  );
}
