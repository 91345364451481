import { useMount } from "ahooks";
import { Skeleton, Typography, message } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import { Formik } from "formik";
import {
  DatePicker,
  Form,
  FormItem,
  Input,
  SubmitButton,
  Switch,
} from "formik-antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";

export default function CrudPage() {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [model, setModel] = useState({});

  const fetchDetail = async function (id) {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.USER_DETAIL, {
        params: {
          userId: id,
        },
      });
      if (rs && rs.status === 0) {
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? rs?.data ?? "Lấy thông tin thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const update = async function (values, actions) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.USER_UPDATE, {
        userId: id,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thành công");
        setModel(rs.data);
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const create = async function (values, actions) {
    if (values.status === undefined || values.status === null) {
      values.status = 0;
    }
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.USER_CREATE, {
        userId: null,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Tạo mới thành công");
        navigate("/user");
      } else {
        message.error(rs?.message ?? "Tạo mới thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useMount(() => {
    if (id) {
      fetchDetail(id);
    }
  }, [id]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="bg-body px-3 rounded">
          <Formik
            initialValues={model}
            onSubmit={(values, actions) => {
              if (id) {
                update(values, actions);
              } else {
                create(values, actions);
              }
            }}
            validationSchema={Yup.object({
              username: Yup.string().required("Yêu cầu nhập tên tài khoản"),
            })}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            children={({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => {
              console.log(values.birthday);
              return (
                <Form
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 8 }}
                  layout="horizontal"
                  size="middle"
                >
                  <FormItem
                    name="username"
                    required={true}
                    label="Tên tài khoản"
                  >
                    <Input
                      name="username"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                      disabled={id ? true : false}
                    />
                  </FormItem>

                  <FormItem name="password" required={true} label="Mật khẩu">
                    <Input
                      name="password"
                      placeholder="Đã ẩn thông tin"
                      size="middle"
                      type="password"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                      disabled={id ? true : false}
                    />
                  </FormItem>
                  <FormItem
                    name="fullName"
                    required={false}
                    label="Tên hiển thị"
                  >
                    <Input
                      name="fullName"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                    />
                  </FormItem>
                  <FormItem name="birthday" required={false} label="Ngày sinh">
                    <DatePicker
                      name="birthday"
                      locale={locale}
                      placeholder="Chọn ngày"
                      size="middle"
                      value={
                        values.birthday
                          ? dayjs(values.birthday, "DD/MM/YYYY HH:mm:ss")
                          : dayjs()
                      }
                      disabledDate={(d) =>
                        !d || d.isAfter(dayjs().add(10, "years"))
                      }
                      format="DD/MM/YYYY"
                      onChange={(date, dateString) => {
                        setFieldValue(
                          "birthday",
                          dayjs(dateString).format("DD/MM/YYYY HH:mm:ss")
                        );
                      }}
                    />
                  </FormItem>
                  <FormItem name="email" required={false} label="Email">
                    <Input
                      name="email"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                    />
                  </FormItem>
                  <FormItem name="phone" required={false} label="Phone">
                    <Input
                      name="phone"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                    />
                  </FormItem>
                  <FormItem name="address" required={false} label="Địa chỉ">
                    <Input
                      name="address"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                    />
                  </FormItem>
                  <FormItem
                    name="workplace"
                    required={false}
                    label="Nơi làm việc"
                  >
                    <Input
                      name="workplace"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                    />
                  </FormItem>
                  <FormItem name="status" required={false} label="Trạng thái">
                    <Switch
                      name="status"
                      size="middle"
                      checked={values.status === 1 ? false : true}
                      onChange={(checked) => {
                        setFieldValue("status", checked === true ? 0 : 1);
                      }}
                      checkedChildren="0"
                      unCheckedChildren="1"
                    />
                  </FormItem>

                  <div className="d-flex flex-column align-items-start">
                    <SubmitButton
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      style={{
                        width: "150px",
                      }}
                    >
                      {id ? "Cập nhật" : "Tạo mới"}
                    </SubmitButton>
                  </div>
                </Form>
              );
            }}
          ></Formik>
        </div>
      )}
    </div>
  );
}
