import { Route, Routes } from "react-router-dom";
import CrudPage from "./crud";
import ListPage from "./list";

export default function UserRouter() {
  return (
    <Routes>
      <Route path="" element={<ListPage />} />
      <Route path="/detail/:id" element={<CrudPage />} />
      <Route path="/create" element={<CrudPage />} />
    </Routes>
  );
}
