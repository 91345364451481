import { Skeleton, Typography, message } from "antd";
import { Formik } from "formik";
import { Form, FormItem, InputNumber, Select, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import APIConst from "../../../network/apiconst";
import axiosClient from "../../../network/restclient";
import { NumericFormat } from "react-number-format";

export default function ElemntCrudPage({ colorFormulaId, element, onSuccess }) {
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [colorElementCode, setColorElementCode] = useState([]);
  const [sellPricePerKg, setSellPricePerKg] = useState(
    element?.sellPricePerKg ?? 0
  );

  const fetchColorElementCodes = async function () {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.COLOR_ELEMENT_CODE, {
        params: {
          keyword: "",
        },
      });
      if (rs && rs.status === 0) {
        setColorElementCode(rs.data);
      } else {
        message.error(rs?.message ?? "Lấy mã TPM dữ liệu thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const update = async function (values, actions) {
    values.colorFormulaId = colorFormulaId;
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.COLOR_ELEMENT_UPDATE, {
        colorElementId: element?.colorElementId,
        ...values,
      });
      if (rs && rs.status === 0) {
        rs.data.sellPricePerKg = sellPricePerKg;
        onSuccess(rs.data);
        message.success("Cập nhật thành công");
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const create = async function (values, actions) {
    values.colorFormulaId = colorFormulaId;
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.COLOR_ELEMENT_CREATE, {
        colorElementId: null,
        ...values,
      });
      if (rs && rs.status === 0) {
        message.success("Tạo mới thành công");
        rs.data.sellPricePerKg = sellPricePerKg;
        onSuccess(rs.data);
      } else {
        message.error(rs?.message ?? "Cập nhật thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchColorElementCodes();
    return () => {};
  }, []);

  return (
    <div className="bg-body p-3 h-100 mw-100 rounded">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="bg-body px-3 rounded">
          <Formik
            initialValues={
              element ?? {
                code: "",
                bottled: 1.0,
                bottledRatio: 1.0,
                mixingRatio: 1.0,
                buyPrice: 0,
                sellPrice: 0,
              }
            }
            onSubmit={(values, actions) => {
              if (element?.colorElementId) {
                update(values, actions);
              } else {
                create(values, actions);
              }
            }}
            validationSchema={Yup.object({
              code: Yup.string().required("Mã thành phần không được để trống"),
            })}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            enableReinitialize={true}
            children={({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                layout="horizontal"
                size="middle"
              >
                <FormItem name="code" required={true} label="Mã thành phần màu">
                  <Select
                    showSearch={true}
                    name="code"
                    placeholder="Chưa có thông tin"
                    size="middle"
                    allowClear={true}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input) ||
                      (option?.value ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={colorElementCode
                      .filter((item) => item.status !== 1)
                      .map((item) => ({
                        label: `${item.code} - ${item.name}`,
                        value: item.code,
                      }))}
                    onChange={(value) => {
                      setFieldValue("code", value);
                      const item = colorElementCode.find(
                        (item) => item.code === value
                      );
                      if (item) {
                        setSellPricePerKg(item.sellPricePerKg);
                      }
                    }}
                  />
                </FormItem>
                <FormItem
                  name="sellPricePerKg"
                  required={false}
                  label="Giá bán/Kg"
                >
                  <NumericFormat
                    name="sellPricePerKg"
                    value={sellPricePerKg ?? 0}
                    displayType="text"
                    suffix=" đ"
                    thousandSeparator={true}
                    style={{
                      fontWeight: 500,
                      color: "red",
                    }}
                  />
                </FormItem>
                <FormItem
                  name="mixingWeight"
                  required={false}
                  label="Khối lượng trộn"
                >
                  <InputNumber
                    name="mixingWeight"
                    placeholder="Chưa có thông tin"
                    size="middle"
                    defaultValue={0}
                    allowClear={true}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="false"
                    style={{ width: "50%" }}
                    suffix="gram"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </FormItem>
                <div className="d-flex flex-column align-items-start">
                  <SubmitButton
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{
                      width: "150px",
                    }}
                  >
                    {element ? "Cập nhật" : "Tạo mới"}
                  </SubmitButton>
                </div>
              </Form>
            )}
          ></Formik>
        </div>
      )}
    </div>
  );
}
