import {
  ApartmentOutlined,
  MessageOutlined,
  ProductOutlined,
  PushpinOutlined,
  FormatPainterOutlined,
  CarOutlined,
  UserOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { Card, Flex, Statistic, message } from "antd";
import React, { useEffect, useState } from "react";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";

export default function HomeContent() {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(APIConst.DASHBOARD, {
        params: {},
      });
      if (res && res.status === 0) {
        setModel(res.data);
      } else {
        message.error(res.message ?? "Không lấy được thông tin");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div>
      <Flex gap="middle" vertical>
        <Flex vertical={false}>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Danh mục"
              value={model?.totalCategory ?? 0}
              loading={loading}
              prefix={<ApartmentOutlined />}
              suffix=""
            />
          </Card>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Sản phẩm"
              value={model?.totalProduct ?? 0}
              loading={loading}
              prefix={<ProductOutlined />}
              suffix=""
            />
          </Card>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Sản phẩm hot"
              value={model?.totalProductHot ?? 0}
              loading={loading}
              prefix={<PushpinOutlined />}
              suffix=""
            />
          </Card>
        </Flex>
        <Flex vertical={false}>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Công thức màu"
              value={model?.totalColorFormula ?? 0}
              loading={loading}
              prefix={<FormatPainterOutlined />}
              suffix=""
            />
          </Card>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Tổng số đời xe"
              value={model?.totalCarModel ?? 0}
              loading={loading}
              prefix={<CarOutlined />}
              suffix=""
            />
          </Card>

          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Đời xe"
              value={model?.totalCarModel ?? 0}
              loading={loading}
              prefix={<CarOutlined />}
              suffix=""
            />
          </Card>
        </Flex>
        <Flex vertical={false}>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Người dùng"
              value={model?.totalUser ?? 0}
              loading={loading}
              prefix={<UserOutlined />}
              suffix=""
            />
          </Card>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="File tải lên"
              value={model?.totalFile ?? 0}
              loading={loading}
              prefix={<CloudUploadOutlined />}
              suffix=""
            />
          </Card>
          <Card
            bordered={true}
            className="ms-2"
            style={{
              width: 160,
              height: 160,
            }}
          >
            <Statistic
              title="Thông báo gửi"
              value={model?.totalNotify ?? 0}
              loading={loading}
              prefix={<MessageOutlined />}
              suffix=""
            />
          </Card>
        </Flex>
      </Flex>
    </div>
  );
}
