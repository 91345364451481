import { LogoutOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  Layout,
  Menu,
  Skeleton,
  Typography,
  message,
} from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content, Footer } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { adminAtom } from "../../data/atoms";
import menus from "./menu";
import axiosClient from "../../network/restclient";
import APIConst from "../../network/apiconst";
import { useMount } from "ahooks";

export default function HomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Title, Text } = Typography;

  const user = useRecoilValue(adminAtom);
  const setAdminAtom = useSetRecoilState(adminAtom);

  const [loading, setLoading] = useState("");

  const [collapsed, setCollapsed] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [openTabs, setOpenTabs] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setsubtitle] = useState("");
  const [logo, setLogo] = useState("/logo/logo.png");

  const fetchDetail = async () => {
    try {
      const res = await axiosClient.get(APIConst.ACC_DETAIL);
      if (res && res.status === 0) {
        setAdminAtom(res.data);
      } else {
        message.error(res?.message ?? "Không lấy được thông tin user");
        logout();
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
  };

  const logout = async () => {
    localStorage.removeItem("auth_token");
    setAdminAtom(null);
    navigate("/login");
  };

  useEffect(() => {
    fetchDetail();
    return () => {};
  }, []);

  return (
    <Layout className="d-flex flex-row" style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="dark"
        style={{ width: 256 }}
      >
        <div
          className="d-flex align-items-center justify-content-center p-5"
          style={{ height: 130 }}
        >
          <Image className="logo" src={logo} preview={false} />
        </div>
        <Menu
          defaultSelectedKeys={selectedTabs}
          defaultOpenKeys={["category"]}
          selectedKeys={selectedTabs}
          mode="inline"
          theme="dark"
          size="small"
          inlineCollapsed={collapsed}
          items={menus}
          onClick={({ key }) => {
            const allItems = [];
            menus.forEach((mn) => {
              if (mn.children && mn.children.length > 0) {
                mn.children.forEach((child) => {
                  allItems.push(child);
                });
              } else {
                allItems.push(mn);
              }
            });
            const selected = allItems.find((el) => el.key === key);
            setSelectedTabs([key]);
            if (selected) {
              navigate(selected.keypath, { replace: false });
            }
          }}
        />
      </Sider>
      <Layout className="d-flex flex-column mh-100">
        <div className="d-flex flex-row justify-content-between align-items-center bg-white px-3 py-2 rounded-bottom">
          <div className="d-flex">
            <Title
              level={4}
              style={{
                marginLeft: 24,
              }}
            >
              {title}
            </Title>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center align-content-end bg-white px-4">
            <Text
              className="mx-3"
              style={{ fontSize: 14 }}
              strong
              onClick={() => {
                const token = localStorage.getItem("auth_token");
                navigator.clipboard.writeText(token);
              }}
            >
              {user?.username}
            </Text>
            <Button
              className="mx-3"
              size="middle"
              icon={<LogoutOutlined />}
              onClick={() => {
                logout();
              }}
            />
          </div>
        </div>
        <Content
          className="site-layout-background bg-light p-2"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            width: "calc(100vw - 210px)",
            maxWidth: "calc(100vw - 210px)",
          }}
        >
          {loading ? <Skeleton /> : <Outlet />}
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "#fefefe" }}>
          Minigara ©2024 - Created by hoanganhtran1010@gmail.com
        </Footer>
      </Layout>
    </Layout>
  );
}
