import { DeleteOutlined, EyeOutlined, CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Image,
  List,
  Popconfirm,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";

export default function ListPage() {
  const { Text, Paragraph } = Typography;

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    keyword: "",
    limit: 12,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(APIConst.UPLOAD_LIST, {
        params: {
          limit: searchParams.get("limit"),
          page: searchParams.get("page") - 1,
        },
      });
      if (res && res.status === 0) {
        setList(res.data.list ?? []);
        setTotalResult(res.data.total ?? 0);
      } else {
        message.error(res.message ?? "Không lấy được danh sách ảnh");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  const deleteRecord = async (filePath) => {
    setLoading(true);
    filePath = filePath.replace(process.env.REACT_APP_API_BASE_URL, "");
    try {
      const res = await axiosClient.post(APIConst.UPLOAD_DELETE, {
        filePath: filePath,
      });
      if (res && res.status === 0) {
        fetchData();
      } else {
        message.error(res.message ?? "Không xóa được ảnh");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, [
    searchParams.get("refresh"),
    searchParams.get("page"),
    searchParams.get("limit"),
  ]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      <div className="mt-3">
        <List
          grid={{ gutter: 20, column: 4 }}
          dataSource={list}
          loading={loading}
          size="small"
          pagination={{
            total: totalResult,
            pageSize: searchParams.get("limit"),
            current: searchParams.get("page"),
            showTotal: (total, range) =>
              `Hiển thị ${range[0]}-${range[1]} trong tổng số ${total} ảnh`,
            onChange: (page, pageSize) => {
              setSearchParams({ page: page, limit: pageSize });
            },
            onShowSizeChange: (current, size) => {
              setSearchParams({ page: current, limit: size });
            },
          }}
          renderItem={(item) => (
            <List.Item>
              <Card
                size="small"
                bordered={true}
                style={{ width: 260, height: 310 }}
                cover={
                  <Image
                    src={`${process.env.REACT_APP_API_BASE_URL}${item.filePath}`}
                    alt={item.fileName}
                    preview={true}
                    style={{ height: 180, objectFit: "cover" }}
                  />
                }
                actions={[
                  <Button
                    type="link"
                    size="small"
                    icon={<EyeOutlined />}
                    onClick={() => {
                      navigate(
                        `${process.env.REACT_APP_API_BASE_URL}${item.filePath}`
                      );
                    }}
                  />,
                  <Button
                    type="link"
                    size="small"
                    icon={<CopyOutlined />}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_API_BASE_URL}${item.filePath}`
                      );
                      message.success("Đã copy đường dẫn ảnh");
                    }}
                  />,
                  <Popconfirm
                    title="Bạn có chắc chắn muốn xóa ảnh này?"
                    onConfirm={() => deleteRecord(item.filePath)}
                    okText="Có"
                    cancelText="Không"
                  >
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      danger
                      size="small"
                    />
                  </Popconfirm>,
                ]}
              >
                <Card.Meta
                  title={item.fileName}
                  description={dayjs(item.createdAt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                />
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
