import { List, Typography, message } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, ResetButton, SubmitButton } from "formik-antd";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";
import FormikRichText from "../../component/richtext";

export default function NotifyPage() {
  const { Text, Paragraph, Title } = Typography;

  const [list, setList] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axiosClient.get(APIConst.NOTIFY_LIST, {
        params: {
          limit: pageSize,
          page: page,
        },
      });
      if (res && res.status === 0) {
        setList(res.data.list ?? []);
        setTotalResult(res.data.total ?? 0);
      } else {
        message.error(res.message ?? "Không lấy được danh sách notify");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    setLoading(false);
  };

  const send = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const res = await axiosClient.post(APIConst.NOTIFY_SEND, {
        title: values.title,
        shortMessage: values.shortMessage,
        content: values.content,
      });
      if (res && res.status === 0) {
        message.success("Gửi thông báo thành công");
        fetchData();
      } else {
        message.error(res.message ?? "Gửi thông báo thất bại");
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    }
    actions.setSubmitting(false);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      <div className="d-flex flex-row">
        <div className="h-100 w-50 me-2 align-items-center">
          <Formik
            initialValues={{
              title: "",
              shortMessage: "",
              content: "",
            }}
            onSubmit={(values, actions) => {
              send(values, actions);
            }}
            validationSchema={Yup.object({
              title: Yup.string().required("Vui lòng nhập tiêu đề"),
              shortMessage: Yup.string().required("Vui lòng nhập mẩu tin ngắn"),
              content: Yup.string().required("Vui lòng nhập nội dung"),
            })}
            validateOnChange={false}
            validateOnBlur={true}
            validateOnMount={true}
            enableReinitialize={true}
            children={({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <Form
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  layout="horizontal"
                  size="middle"
                  title="Gửi thông báo"
                >
                  <FormItem name="title" required={true} label="Tiêu đề">
                    <Input
                      name="title"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                    />
                  </FormItem>

                  <FormItem
                    name="shortMessage"
                    required={true}
                    label="Mẩu tin"
                  >
                    <Input.TextArea
                      name="shortMessage"
                      placeholder="Chưa có thông tin"
                      size="middle"
                      allowClear={true}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="false"
                      rows={3}
                    />
                  </FormItem>

                  <FormItem name="content" required={true} label="Nội dung">
                    <FormikRichText
                      id="frt"
                      value={values.content}
                      height={150}
                      setValue={(value) => {
                        setFieldValue("content", value);
                      }}
                    />
                  </FormItem>

                  <div className="d-flex flex-row align-items-start">
                    <SubmitButton
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      style={{
                        width: "150px",
                      }}
                    >
                      Gửi tin
                    </SubmitButton>
                    <ResetButton
                      className="ms-3"
                      type="default"
                      size="middle"
                      style={{
                        width: "150px",
                      }}
                    >
                      Làm lại
                    </ResetButton>
                  </div>
                </Form>
              );
            }}
          ></Formik>
        </div>
        <div className="h-100 w-50 ms-1">
          <List
            className="h-100"
            itemLayout="vertical"
            dataSource={list ?? []}
            loading={loading}
            size="small"
            pagination={{
              total: totalResult,
              current: page,
              pageSize: pageSize,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]}/${total} tin đã gửi`,
              size: "small",
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
            renderItem={(item) => (
              <List.Item>
                <div className="d-flex flex-column">
                  <Text strong>{item.title}</Text>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                    }}
                  >
                    {item.shortMessage}
                  </Paragraph>
                  <Text type="secondary">Gửi lúc: {item.createdAt}</Text>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
}
