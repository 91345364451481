class APIConst {
  static LOGIN = "/cms/admin/login";
  static ACC_DETAIL = "/cms/admin/detail";

  static USER_LIST = "/cms/user/list";
  static USER_DETAIL = "/cms/user/detail";
  static USER_UPDATE = "/cms/user/update";
  static USER_CREATE = "/cms/user/create";
  static USER_DELETE = "/cms/user/delete";

  static CATE_LIST = "/cms/category/list";
  static CATE_DETAIL = "/cms/category/detail";
  static CATE_UPDATE = "/cms/category/update";
  static CATE_CREATE = "/cms/category/create";
  static CATE_DELETE = "/cms/category/delete";

  static PRODUCT_LIST = "/cms/product/list";
  static PRODUCT_CATE = "/cms/product/cate";
  static PRODUCT_HOT = "/cms/product/hot";
  static PRODUCT_DETAIL = "/cms/product/detail";
  static PRODUCT_UPDATE = "/cms/product/update";
  static PRODUCT_CREATE = "/cms/product/create";
  static PRODUCT_DELETE = "/cms/product/delete";

  static PRODUCT_JOIN_CATE = "/cms/product/join-category";
  static PRODUCT_LEAVE_CATE = "/cms/product/leave-category";
  static PRODUCT_SORT_CATE = "/cms/product/sort";

  static COLOR_LIST = "/cms/color/list";
  static COLOR_DETAIL = "/cms/color/detail";
  static COLOR_UPDATE = "/cms/color/update";
  static COLOR_CREATE = "/cms/color/create";
  static COLOR_DELETE = "/cms/color/delete";
  static COLOR_ELEMENT_LIST = "/cms/color/element";
  static COLOR_ELEMENT_CREATE = "/cms/color/element/create";
  static COLOR_ELEMENT_UPDATE = "/cms/color/element/update";
  static COLOR_ELEMENT_DELETE = "/cms/color/element/delete";
  static COLOR_ELEMENT_CODE = "/cms/color/element/code";
  static COLOR_PAINT = "/cms/color/paint";

  static CAR_BRAND = "/cms/car/brand";
  static CAR_VEHICLES = "/cms/car/vehicles";
  static CAR_MODEL = "/cms/car/model";

  static NOTIFY_LIST = "/cms/notify/list";
  static NOTIFY_SEND = "/cms/notify/send";

  static UPLOAD_FILE = "/cms/resource/upload";
  static UPLOAD_LIST = "/cms/resource/list";
  static UPLOAD_DELETE = "/cms/resource/delete";

  static DASHBOARD = "cms/dashboard";
}
export default APIConst;
