import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Image,
  Table,
  Tag,
  Typography,
  message,
  InputNumber,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import APIConst from "../../network/apiconst";
import axiosClient from "../../network/restclient";
import { NumericFormat } from "react-number-format";
import PickPage from "../product/pick";
import Link from "antd/es/typography/Link";

export default function HotPage() {
  const { Text, Paragraph } = Typography;

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    keyword: "",
    limit: 5,
    page: 1,
  });

  const [showPickProduct, setShowPickProduct] = useState(false);

  const fetchProducts = async function () {
    setLoading(true);
    try {
      const rs = await axiosClient.get(APIConst.PRODUCT_CATE, {
        params: {
          keyword: searchParams.get("keyword"),
          categoryId: -1,
        },
      });
      if (rs && rs.status === 0) {
        setList(rs.data);
      } else {
        message.error(rs?.message ?? "Lấy thông tin products thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const leaveCategory = async function (productId) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_LEAVE_CATE, {
        productId: productId,
        categoryId: -1, // -1 is hot category
      });
      if (rs && rs.status === 0) {
        message.success("Xóa sản phẩm khỏi danh mục hot thành công");
        fetchProducts();
      } else {
        message.error(rs?.message ?? "Xóa sản phẩm khỏi danh mục hot thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const joinCategory = async function (productId) {
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_JOIN_CATE, {
        productId: productId,
        categoryId: -1, // -1 is hot category
      });
      if (rs && rs.status === 0) {
        message.success("Thêm sản phẩm vào danh mục hot thành công");
        setShowPickProduct(false);
        fetchProducts();
      } else {
        message.error(rs?.message ?? "Thêm sản phẩm vào danh mục hot thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const changeSort = async function (productId, sort) {
    if (sort < 0) {
      message.error("Thứ tự phải lớn hơn 0");
      return;
    }
    setLoading(true);
    try {
      const rs = await axiosClient.post(APIConst.PRODUCT_SORT_CATE, {
        productId: productId,
        categoryId: -1, // -1 is hot category
        sort: sort,
      });
      if (rs && rs.status === 0) {
        message.success("Cập nhật thứ tự sản phẩm thành công");
        fetchProducts();
      } else {
        message.error(rs?.message ?? "Cập nhật thứ tự sản phẩm thất bại");
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
    return () => {};
  }, [searchParams.get("refresh")]);

  return (
    <div className="bg-body p-3 h-100 rounded">
      <Modal
        className="mw-80"
        title="Chọn sản phẩm"
        open={showPickProduct}
        onCancel={() => {
          setShowPickProduct(false);
        }}
        footer={null}
        style={{
          minWidth: "80%",
        }}
      >
        <PickPage
          selectedIds={list.map((item) => item.productId)}
          onPick={(product) => {
            joinCategory(product.productId);
          }}
        />
      </Modal>
      <div className="d-flex flex-row py-2 justify-content-between align-items-center">
        <div>
          <Input
            placeholder="Tìm kiếm tên sản phẩm"
            enterbutton="Tìm kiếm"
            name="search"
            value={searchParams.get("keyword")}
            allowClear={true}
            size="middle"
            onChange={(e) => {
              searchParams.set("keyword", e.target.value);
              setSearchParams(searchParams);
            }}
            style={{
              width: 300,
            }}
          />
          <Button
            type="primary"
            size="middle"
            onClick={(e) => {
              searchParams.set("refresh", Math.random());
              setSearchParams(searchParams);
            }}
            icon={<SearchOutlined />}
            className="ms-2"
          >
            Tìm kiếm
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowPickProduct(true);
            }}
          >
            Thêm SP
          </Button>
        </div>
      </div>
      <div className="mt-3">
        <div className="mb-3">
          <Text>Danh sách sản phẩm có trong mục nổi bật</Text>
        </div>
        <Table
          rowKey="_id"
          dataSource={list || []}
          loading={loading}
          bordered={true}
          size="small"
          scroll={{ x: "max-content" }}
          responsive={true}
          columns={[
            {
              title: "ID",
              dataIndex: "productId",
              key: "productId",
              align: "left",
              render: (text) => <Text>{text}</Text>,
            },
            {
              title: "Thumbnail",
              dataIndex: "thumbnail",
              key: "thumbnail",
              align: "center",
              render: (text) => (
                <Image
                  width={50}
                  height={50}
                  src={text}
                  alt="thumbnail"
                  preview={false}
                  fallback="https://via.placeholder.com/50"
                />
              ),
            },
            {
              title: "Tên sản phẩm",
              dataIndex: "name",
              key: "name",
              align: "left",
              width: 200,
              render: (text, item) => (
                <Link
                  href={`/product/detail/${item.productId}`}
                  style={{
                    fontWeight: 500,
                    textDecoration: "underline",
                  }}
                >
                  {text}
                </Link>
              ),
            },
            {
              title: "Mô tả",
              dataIndex: "description",
              key: "description",
              align: "left",
              width: 350,
              render: (text) => (
                <Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                >
                  {text}
                </Paragraph>
              ),
            },
            {
              title: "Giá",
              dataIndex: "price",
              key: "price",
              align: "center",
              render: (text, item) => (
                <Text>
                  {item.contactPrice === 1 ? (
                    <Tag color="magenta">Liên hệ</Tag>
                  ) : (
                    <NumericFormat
                      value={text}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={" đ"}
                      style={{
                        color: "red",
                        fontWeight: 500,
                      }}
                    />
                  )}
                </Text>
              ),
            },
            {
              title: "Thứ tự",
              dataIndex: "sort",
              key: "sort",
              align: "center",
              render: (text, item) => (
                <InputNumber
                  value={text}
                  min={1}
                  max={1000}
                  onChange={(value) => {
                    item.sort = value;
                  }}
                  onPressEnter={() => {
                    changeSort(item.productId, item.sort);
                  }}
                />
              ),
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              fixed: "right",
              align: "center",
              width: 90,
              render: (text, item) => (
                <div>
                  <Button
                    type="link"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      leaveCategory(item.productId);
                    }}
                  >
                    Xóa bỏ
                  </Button>
                </div>
              ),
            },
          ]}
          pagination={{
            total: totalResult,
            showSizeChanger: false,
            pageSizeOptions: ["5", "10", "20", "50"],
            defaultPageSize: 5,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} / ${total} kết quả`,
          }}
        />
      </div>
    </div>
  );
}
