import axios from "axios";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.defaults.timeout = 10 * 60 * 1000;

axiosClient.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem("auth_token");
    config.headers["minigara-verify"] = process.env.REACT_APP_API_VERIFY;
    config.headers["Authorization"] = token;
    return config;
  },
  function (error) {}
);

axiosClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {}
);

const redirectToLogin = () => {
  // const history = useHistory();
  // history.push("/login");
};

export const clearAxiosToken = () => {
  axiosClient.defaults.headers["Authorization"] = "";
  localStorage.removeItem("auth_token");
};

export const udpateAxiosToken = (token) => {
  axiosClient.defaults.headers["Authorization"] = token;
  localStorage.setItem("auth_token", token);
};

export default axiosClient;
