import { message } from "antd";
import React, { useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import axiosClient from "../network/restclient";
import APIConst from "../network/apiconst";
import ImageResize from "quill-image-resize-module-react";

export default function FormikRichText({ id, value, setValue, height = 100 }) {
  const quillRef = useRef();
  Quill.register("modules/imageResize", ImageResize);

  const unescapeHtml = (escapedString) => {
    return escapedString
      ? escapedString.replace(/\\"/g, '"').replace(/\\'/g, "'")
      : escapedString;
  };

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        console.log(file);
        const formData = new FormData();
        formData.append("file", file);
        try {
          const response = await axiosClient.post(
            APIConst.UPLOAD_FILE,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response) {
            const range = editor.getSelection(true);
            editor.insertEmbed(range.index, "image", response);
          } else {
            message.error(response?.message ?? "Upload thất bại");
          }
        } catch (err) {
          message.error("Upload thất bại " + err.message);
        }
      } else {
        message.error("Bạn chỉ được phép upload file ảnh");
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ font: [] }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      // imageResize: {
      //   parchment: Quill.import("parchment"),
      // },
    }),
    []
  );
  return (
    <div onDrop={(e) => e.preventDefault()}>
      <ReactQuill
        id={id}
        theme="snow"
        ref={quillRef}
        value={unescapeHtml(value)}
        modules={modules}
        onChange={setValue}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "align",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
        ]}
      />
    </div>
  );
}
